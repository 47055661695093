<template>
    <ModalDialogContainer ref="modalContainer" v-model:display="displayStatus">
        <div class="modal-dialog modal-md" role="document" @click="stopPropagationEvent">
            <div class="modal-header">
                <div class="modal-title">{{ title }}</div>
                <button type="button" class="modal-close-btn" :title="$t('Close')" @click="close">
                    <i class="fas fa-times"></i>
                </button>
            </div>
            <div class="modal-body">
                <p v-html="message"></p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn cancel-btn btn-mr" @click="close">
                    {{ $t("Cancel") }}
                </button>
                <button type="button" class="btn btn-primary btn-mr" :class="{ 'btn-primary': !danger, 'btn-danger': danger }"
                    @click="confirm">
                    <i class="fas fa-check"></i> {{ $t("Confirm") }}
                </button>
            </div>
        </div>
    </ModalDialogContainer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useVModel } from "../../utils/v-model";

export default defineComponent({
    name: "ConfirmationModal",
    emits: ["update:display", "confirm"],
    props: {
        display: Boolean,
        title: String,
        message: String,
        danger: Boolean,
    },
    setup(props) {
        return {
            displayStatus: useVModel(props, "display"),
        };
    },
    methods: {
        close: function () {
            this.$closeModal();
        },

        escapeToClose: function (event) {
            if (event.key === "Escape") {
                this.close();
            }
        },

        stopPropagationEvent: function (e) {
            e.stopPropagation();
        },

        confirm: function () {
            this.$emit("confirm");
            this.close();
        },
    },
    mounted: function () {
        if (this.display) {
            this.$autoFocus();
        }
    },
    watch: {
        display: function () {
            if (this.display) {
                this.$autoFocus();
            }
        },
    },
});
</script>

<style></style>
